/* eslint-disable camelcase */
const createSeoMeta = (data = {}, { title, description, og_title, og_description, og_image, twitter_title, twitter_description, twitter_image }) => {
  const DEFAULT_META_DATA = {
    title: title || '',
    description: description || '',
    og_title: og_title || title || '',
    og_description: og_description || description || '',
    og_image: og_image || '',
    twitter_title: twitter_title || title || '',
    twitter_description: twitter_description || description || '',
    twitter_image: twitter_image || ''
  }
  const meta = [
    {
      hid: 'description',
      name: 'description',
      content: data.description || DEFAULT_META_DATA.description
    },
    {
      hid: 'og:site_name',
      property: 'og:site_name',
      content: 'Umseck'
    },
    {
      hid: 'og:type',
      property: 'og:type',
      content: data.og_type || 'website'
    },
    {
      hid: 'og:title',
      property: 'og:title',
      content: data.ogTitle || data.og_title || DEFAULT_META_DATA.og_title
    },
    {
      hid: 'og:description',
      property: 'og:description',
      content:
        data.ogDescription ||
        data.og_description ||
        DEFAULT_META_DATA.og_description
    },
    {
      hid: 'og:image',
      property: 'og:image',
      content: data.ogImage || data.og_image || DEFAULT_META_DATA.og_image
    },
    {
      hid: 'twitter:title',
      name: 'twitter:title',
      content:
        data.twitterTitle || data.twitter_title || DEFAULT_META_DATA.twitter_title
    },
    {
      hid: 'twitter:description',
      name: 'twitter:description',
      content:
        data.twitterDescription ||
        data.twitter_description ||
        DEFAULT_META_DATA.twitter_description
    },
    {
      hid: 'twitter:image',
      name: 'twitter:image',
      content:
        data.twitterImage || data.twitter_image || DEFAULT_META_DATA.twitter_image
    }
  ]
  const head = {
    title: data?.title || DEFAULT_META_DATA.title,
    meta
  }
  return head
}

export default {
  head () {
    const metaTags = this.childStory?.content?.metaTags || this.story?.content?.metaTags
    return createSeoMeta(metaTags, this.$store?.state?.settings?.default_seo)
  }
}
