//
//
//
//
//
//
//
//
//
//
//

import metatags from '~/mixins/metatags'
import storyblokLivePreview from '~/mixins/storyblokLivePreview'

function getStoryRelations () {
  const relations = [
    'blok_grid.source'
  ]
  return relations.join(',')
}

export default {
  mixins: [metatags, storyblokLivePreview],
  scrollToTop: false,
  async asyncData ({ $storyapi, error, route, query, isDev }) {
    try {
      const fullSlug = 'home'
      const payload = {
        resolve_links: 'url',
        cv: new Date().getTime(),
        resolve_relations: getStoryRelations(),
        version: process?.env?.APP_ENV !== 'production' ? 'draft' : 'published'
      }

      const currentPage = await $storyapi.getStory(fullSlug, payload)

      return {
        story: currentPage.data.story
      }
    } catch (e) {
      // console.error('Exception', e)
      error({
        statusCode: 404,
        message: e.message
      })
    }
  },
  computed: {
    isInnChild () {
      return this.$route.name === 'index-wirtshaeuser-inn'
    }
  }
}
